import "./App.css";
import Home from "./Pages/Home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomeLoan from "./Pages/HomeLoan";
import PersonalLoan from "./Pages/PersonalLoan";
import LoanAgainstProperty from "./Pages/LoanAgainstProperty";
import CarLoan from "./Pages/CarLoan";

import BusinessLoan from "./Pages/BusinessLoan";
import CarInsurance from "./Pages/CarInsurance";
import MedicalInsurance from "./Pages/MedicalInsurance";
import HomeInsurance from "./Pages/HomeInsurance";
import AboutPage from "./Pages/AboutPage";
import CombinedLoans from "./Pages/CombinedLoans";
import ServicesPage from "./Pages/ServicesPage";
import ContactUs from "./Pages/ContactUs";

function App() {
  return (
    <>
      <Router>
        <>
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/homeloan" element={<HomeLoan />} />
            <Route path="/personalloan" element={<PersonalLoan />} />
            <Route
              path="/loanagainstproperty"
              element={<LoanAgainstProperty />}
            />
            <Route path="/aboutus" element={<AboutPage />} />
            <Route path="/contactus" element={<ContactUs />} />
            <Route path="/carloan" element={<CarLoan />} />
            <Route path="/loan" element={<CombinedLoans />} />
            <Route path="/businessloan" element={<BusinessLoan />} />
            <Route path="/homeinsurance" element={<HomeInsurance />} />
            <Route path="/medicalinsurance" element={<MedicalInsurance />} />
            <Route path="/carinsurance" element={<CarInsurance />} />
            <Route path="/services" element={<ServicesPage />} />

          </Routes>
        </>
      </Router>
    </>
  );
}

export default App;
