import React from 'react'
import "./ContactInfo.css"
function ContactInfo() {
    const offices = [
        {
          title: "Mumbai",
          address: "Office Number 916/16, Nilkamal Chs, Sector-9, Charkop Road, Kandivali West, Mumbai - 400067 (Near By Ravi Tower)",
          contact: "Contact Us",
        },
        {
          title: "Justdial",
          address: "Registered On Justdial, We are Available!",
          contact: "Visit",
          links:"https://jsdl.in/DT-43YNLFGKXDG"
        },
        {
          title: "Call Us",
          address: "+91 08460499336/+91 9136043666 or support@bankfincon.com   / anujagaikwad@bankfincon.com",
          contact: "Contact Us",
        },
      ];
  return (
    <div>
      <div className="contact-page">
      <div className="header">
        <h1 id='contactus-heading'>Contact Us</h1>
        <p>BankFincon The Banking Partner For You</p>
      </div>
      <div className="cards-container">
        {offices.map((office, index) => (
          <div className="card" key={index}>
            <h3>{office.title}</h3>
            <p>{office.address}</p>
            <a href={office.links}>{office.contact} →</a>
          </div>
        ))}
      </div>
    </div>
    </div>
  )
}

export default ContactInfo
