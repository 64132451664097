import React from 'react';
import './GetInTouch.css'; // Import the specific styles for GetInTouch
import Card from './CardAbout';
import email from "../assets/Group 67.png"
import facebook from "../assets/Facebook.png"
import instagram from "../assets/Instagram.png"
import call from "../assets/Call.png"
import whatsapp from "../assets/WhatsApp.png"




const GetInTouch = () => {
  return (
    <div className="get-in-touch">
      <br />

      <h2>Get In Touch With Us!</h2>

      <div className="cards-container">
        <Card img={email} title="E-mail us" description="support@bankfincon.com" buttonText="Send Email" />

        <Card img={call} title="Book a Call" description="+91 91360 43666" buttonText="Call" />

        <Card img={whatsapp} title="Whatsapp" description="+91 91360 43666" buttonText="Message Us!" />

        <Card img={instagram} title="Instagram" description="@bank_fincon" buttonText="Follow Us!" />
        
        <Card img={facebook}title="Facebook" description="@bankfincon" buttonText="Follow Us!" />
      </div>
    </div>
  );
};

export default GetInTouch;
