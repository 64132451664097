import React from "react";
import "./Navbar.css";
import logo from "../assets/logo.png";
import { Link } from "react-router-dom";



const Navbar = () => {
  return (
    <nav className="navbar" id="top">

      <div className="navbar-logo">
        <a href="/"><img src={logo} alt="Logo" /></a>
        <span>BankFincon</span>
        <span id="tagline">Driving Success, Building Trust</span>
        
      </div>


      <button className="navbar-toggler"  >
        ☰
      </button>
      <div className="navbar-links" id="navbarLinks">
        <Link to="/"  ><b>Home</b></Link>
        
        <Link to="/aboutus">About Us</Link>

        
        <Link to="/services">Services</Link>
        <Link to="/contactus" className="skyblue-button ">Contact Us</Link>
      </div>
    </nav>
    
  );
};

export default Navbar;
