import React from 'react'
import "./Benifits.css"
import puzzleImage from "../assets/puzzleImage.png"
import Label from './Label'
function Benfits() {
  return (
   <>
   
      <p id='whyus'>- Why Us?</p>
      <div className="whyUs-container">
      <div className="left-half-container">
        <h1>The Bank Fincon </h1>
        <h2 >Difference</h2>
        <img src={puzzleImage} alt="" />
        <br /><br />
        <br />
        <p>Ready to Excel?</p> 
        <p>Join <strong>Bank Fincon</strong> Today</p>
        <br />
        <button className='appointmentButton'><a href="https://wa.me/+919136043666" target="_blank" rel="noopener noreferrer">Book a Call</a></button>
      </div>


      <div className="right-half-container">

      <Label/>


      </div>
   </div>
            
   </>
  )
}

export default Benfits
