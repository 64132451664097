import React from "react";
import Navbar from "../Components/Navbar";
import Introduction from "../Components/Introduction";
import businessloanimage from "../assets/businessloanimage.jpg";
import FeatureAndBenifit from "../Components/FeatureAndBenifit";
import interest from "../assets/interest.png";
import hasslefree from "../assets/hasselfree.png";
import norepayment from "../assets/norepayment.png";
import doorstep from "../assets/doorstep.png";
import LoanDetail from "../Components/LoanDetail";
import InformationLinks from "../Components/InformationLinks";
import Eligibility from "../Components/Eligibility";
import WhyBankFinconBusiness from "../Components/WhyBankFinconBusiness";
import Footer from "../Components/Footer";
import Documentation from "../Components/Documentation";
import businessloanbanner from "../assets/businessloanbanner.jpg";
import "./BusinessLoan.css";
import EMICalculator from "../Components/EMICalculator";


function BusinessLoan() {
  return (
    <div>
      <Navbar />

      <p className="link-address">
        <a href="/">Home</a>
        &nbsp;{'>>>'}&nbsp;
        <a href="/#service">Services</a>
        &nbsp;{'>>>'}&nbsp;
        <a href="/homeloan">Business Loan</a>
      </p>
      <div className="banner-section-for-all">
        <div className="businessloan-line" id="business-loan-id">
          <span>
            {" "}
            Fuel Your Business Dreams – Fast, Flexible, and Hassle-Free Loans!{" "}
            <strong> Business loans!</strong>
          </span>
          <p>
            <button className="btn-primary-business-loan"><a href="https://wa.me/+919136043666" target="_blank" rel="noopener noreferrer">Book a Call</a></button>
          </p>
        </div>
        <img src={businessloanbanner} alt="" />
      </div>

      <Introduction
        title="Business Loan"
        desc="A business loan is a financial arrangement where a lender provides funds to a business for operational needs, expansion, or other business purposes, typically requiring repayment with interest over a specified period."
        roi="12+"
        tenure="30 Years"
        image={businessloanimage}
      /><EMICalculator/>
      <FeatureAndBenifit
        featureone={interest}
        benifitone="Simplified Access & Minimal Documentation"
        featuretwo={hasslefree}
        benifittwo="Hassle Free Transacation"
        featurethree={norepayment}
        benifitthree="Minimum Loan Amount Rs. 50,000"
        featurefour={doorstep}
        benifitfour="On Time Disbursal"
      />
      <LoanDetail
        colonerowone="Loan Amount"
        coltworowone="Upto 5 Cr"
        colonerowtwo="Loan Tenure"
        coltworowtwo="Up to 30 Yrs"
        colonerowthree="Floating Interest Rate %"
        coltworowthree="8.75%-10%"
        colonerowfour="Fixed Interest Rate %"
        coltworowfour="14%"
        colonerowfive="Processing Fees"
        coltworowfive="Upto 1% of Loan Amount + GST (minimum Rs.10000)"
      />

      <InformationLinks />
      <Eligibility />
      <WhyBankFinconBusiness/>
      <Documentation />
      <Footer />
    </div>
  );
}

export default BusinessLoan;
