import React from 'react'
import "./WhyBankFincon.css";
import creditCardImage from "../assets/creditcardtwo.jpg";
function WhyBankFinconCredit() {
  return (
    <div>
      <div>
      <div>
        <div id="Whyus" className="why-bank-fincon-container">
          <div className="why-us-container-left">
            <h2 id="h2-heading">Features and Benefits of Credit Card</h2>
            <br />
            <p>
              Our Credit Cards are designed to provide financial flexibility and rewards on every transaction. Here are some of its key features and benefits: <br /><br />
              
              <b>1. Reward Points</b> <br />
              Earn reward points on every spend, which can be redeemed for exciting offers and gifts. <br /><br />
              
              <b>2. Flexible Credit Limits</b> <br />
              Get a credit limit tailored to your needs, allowing you to manage your expenses conveniently. <br /><br />
              
              <b>3. Contactless Payments</b> <br />
              Experience secure and hassle-free transactions with contactless payment options. <br /><br />
              
              <b>4. Attractive Discounts and Cashback</b> <br />
              Enjoy exclusive discounts and cashback on dining, shopping, travel, and more. <br /><br />
              
              <b>5. EMI Conversion</b> <br />
              Convert high-value transactions into easy monthly installments with flexible tenure options. <br /><br />
              
              <b>6. Global Acceptance</b> <br />
              Use your credit card worldwide for online and in-store purchases. <br /><br />
              
              <b>7. Security Features</b> <br />
              Advanced security measures like OTP, fraud detection, and zero liability on unauthorized transactions ensure your card is safe to use. <br /><br />
              
              Our Credit Card offers convenience, security, and rewards, making it an essential tool for managing your expenses and enjoying exclusive benefits.
            </p>
          </div>
          <img id="loanimg" src={creditCardImage} width="300px" height="300px" alt="Credit Card" />
        </div>
      </div>
    </div>
    </div>
  )
}

export default WhyBankFinconCredit
