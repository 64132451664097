import React from 'react'
import "./ContactUsOption.css"
import facebook from "../assets/Facebook.png"
import whatsapp from "../assets/WhatsApp.png"
import callimage from "../assets/Call.png"
import instagram from "../assets/Instagram.png"
import emailimage from "../assets/Group 67.png"
function ContactUsOption() {
  return (
    <div>
      <div className="contact-options">
      <h2>Getting In Touch With Us!</h2>

      <div className="options-container">
        <div className="option-card">
          <img src={emailimage} alt="Email" className="icon" />
          <h3>E-mail us</h3>
          <p>support@bankfincon.com</p>
          <a href="mailto:support@bankfincon.com" className="action-link">Send Email</a>
        </div>

        <div className="option-card">
          <img src={callimage} alt="Call" className="icon" />
          <h3>Book a Call</h3>
          <p>+91 91360 43666</p>
          <a href="tel:+919136043666" className="action-link">Call</a>
        </div>

        <div className="option-card">
          <img src={whatsapp} alt="WhatsApp" className="icon" />
          <h3>WhatsApp</h3>
          <p>+91 91360 43666</p>
          <a href="https://wa.me/919136043666" target="_blank" rel="noopener noreferrer" className="action-link">Message Us!</a>
        </div>

        <div className="option-card">
          <img src={instagram}alt="Instagram" className="icon" />
          <h3>Instagram</h3>
          <p>@bank_fincon</p>
          <a href="https://instagram.com/bank_fincon" target="_blank" rel="noopener noreferrer" className="action-link">Follow Us!</a>
        </div>

        <div className="option-card">
          <img src={facebook}alt="Facebook" className="icon" />
          <h3>Facebook</h3>
          <p>@bankfincon</p>
          <a href="https://facebook.com/bankfincon" target="_blank" rel="noopener noreferrer" className="action-link">Follow Us!</a>
        </div>
        
      </div>
    </div>
    </div>
  )
}

export default ContactUsOption;
