import React from 'react';
import "./WhyBankFincon.css";
import lapImage from "../assets/lapimagetwo.png";

function WhyBankFinconLAP() {
  return (
    <div>
      <div>
        <div id="Whyus" className="why-bank-fincon-container">
          <div className="why-us-container-left">
            <h2 id="h2-heading">Features and Benefits of Loan Against Property</h2>
            <br />
            <p>
              Our Loan Against Property (LAP) is tailored to help you unlock the value of your residential or commercial property to meet your financial needs. Here are some of its key features and benefits: <br /><br />
              
              <b>1. High Loan Amount</b> <br />
              Leverage your property's value to avail a substantial loan amount for personal or business purposes. <br /><br />
              
              <b>2. Competitive Interest Rates</b> <br />
              Enjoy attractive and competitive interest rates, making repayment affordable and stress-free. <br /><br />
              
              <b>3. Flexible Tenure</b> <br />
              Choose a repayment tenure that suits your financial planning, with options up to 15 years. <br /><br />
              
              <b>4. Continued Property Ownership</b> <br />
              Retain ownership and usage of your property while using it as collateral for the loan. <br /><br />
              
              <b>5. Multipurpose Usage</b> <br />
              Use the funds for diverse needs such as business expansion, education, weddings, medical emergencies, or debt consolidation. <br /><br />
              
              Our Loan Against Property ensures that you can access the funds you need without selling your valuable assets, giving you financial freedom and peace of mind.
            </p>
          </div>
          <img id="loanimg" src={lapImage} width="300px" height="300px" alt="Loan Against Property" />
        </div>
      </div>
    </div>
  );
}

export default WhyBankFinconLAP;
