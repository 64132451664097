import React from "react";
import Navbar from "../Components/Navbar";
import HeroSection from "../Components/HeroSection";
import Services from "../Components/Services";
import Insurance from "../Components/Insurance";
import Statistics from "../Components/Statistics";
import Benfits from "../Components/Benifits";
// import Testimonials from "../Components/Testimonials";
// import FAQ from "../Components/FAQ";
import Lender from "../Components/Lender";
import Form from "../Components/Form";
import Footer from "../Components/Footer";

function Home() {
  return (
    <>
      <Navbar />
      <HeroSection />
      <Services />
      <Insurance/>
      <br /><br /><br /> <br /><br /><br /> <br /><br /><br />
      <Statistics/>
      <Benfits/>
      {/* <Testimonials/> */}
      {/* <FAQ/> */}
      <Lender/>
      <Form/>
      <Footer/>
    </>
  );
}

export default Home;
