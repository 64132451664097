import React from 'react';
import './CardAbout.css'; 
const Card = (props) => {
  return (
    <div className="cardabout">
      <img src={props.img} alt={props.title} />
      <h3>{props.title}</h3>
      <p>{props.description}</p>
      <button>{props.buttonText}</button>
    </div>
  );
};

export default Card;