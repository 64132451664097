import React from 'react'
import "./WhyBankFincon.css";
import businessloan from "../assets/loanimage.png"


function WhyBankFinconPersonal() {
  return (
    <div>
      <div>
      <div id="Whyus" className="why-bank-fincon-container">
        <div className="why-us-container-left">
          <h2 id="h2-heading">Features and Benefits of Our Personal Loan</h2>
          <br />
          <p>
  Our personal loan offerings are designed to meet diverse financial needs with competitive interest rates and flexible EMIs, providing you the freedom to fulfill your goals effortlessly. <br /><br />
  <b>1. Debt Consolidation Loan</b> <br />
  Simplify your finances by combining multiple debts into one manageable loan with a single repayment schedule, helping you reduce financial stress. <br /><br />
  <b>2. Medical Emergency Loan</b> <br />
  Quick and hassle-free funding to cover unexpected medical expenses, ensuring you have financial support during critical times. <br /><br />
  <b>3. Travel Loan</b> <br />
  Plan your dream vacation without worrying about expenses. This loan covers travel costs, accommodations, and other trip essentials. <br /><br />
  <b>4. Wedding Loan</b> <br />
  Make your special day truly memorable with financial support for wedding arrangements, venue bookings, and other expenses. <br /><br />
  <b>5. Education Loan</b> <br />
  Empower yourself or your loved ones with a loan that supports higher education, professional courses, or skill development programs. <br /><br />
  These personalized loan solutions ensure your financial needs are met, helping you achieve your aspirations with ease and convenience.
</p>

        </div>
        <img id="loanimg" src={businessloan} width="300px" height="300px"alt="" />
      </div>
    </div>
    </div>
  )
}

export default WhyBankFinconPersonal
