import React from "react";
import Navbar from "../Components/Navbar";
import personalloanbanner from "../assets/personalloanbanner.jpg";
import Introduction from "../Components/Introduction";
import personalloangirl from "../assets/personalloangirl.jpg";
import FeatureAndBenifit from "../Components/FeatureAndBenifit";
import interest from "../assets/interest.png";
import hasslefree from "../assets/hasselfree.png";
import norepayment from "../assets/norepayment.png";
import doorstep from "../assets/doorstep.png";
import LoanDetail from "../Components/LoanDetail";
import "../Pages/PersonalLoan.css";
import InformationLinks from "../Components/InformationLinks";
import Eligibility from "../Components/Eligibility";
import WhyBankFinconPersonal from "../Components/WhyBankFinconPersonal";
import Documentation from "../Components/Documentation";
import Footer from "../Components/Footer";
import EMICalculator from "../Components/EMICalculator";

function PersonalLoan() {
  return (
    <div>
      <Navbar />
      <p className="link-address">
        <a href="/">Home</a>&nbsp;{">>>"}&nbsp;<a href="/#service">Services</a>
        &nbsp;{">>>"}&nbsp;<a href="/personalloan">Personal Loan</a>
      </p>
      <div className="banner-section-for-all">
        <div className="homeloan-line" id="personalloan-line">
          <span>
            {" "}
            facing Cash Crunch? <br /> <br />
            <b>Personal Loan</b> <br />
            <br /> Never Let insufficient funds be a problem
          </span>

          <p>
            <button id="btn-primary-personal">
              <a
                href="https://wa.me/+919136043666"
                target="_blank"
                rel="noopener noreferrer"
              >
                Book a Call
              </a>
            </button>
          </p>
        </div>

        <img src={personalloanbanner} alt="" />
      </div>
      <Introduction
        title="Personal Loan"
        desc="A home loan is a financial product that helps individuals purchase or construct a house by borrowing funds from a lender, such as a bank or a financial institution. The borrower repays the loan over an agreed-upon tenure through Equated Monthly Installments (EMIs), which include both the principal amount and the interest."
        roi="12+"
        tenure="84 Months"
        image={personalloangirl}
      /><EMICalculator/>
      <FeatureAndBenifit
        featureone={interest}
        benifitone="Attractive Rate Of Interest"
        featuretwo={hasslefree}
        benifittwo="Hassle Free Transacation"
        featurethree={norepayment}
        benifitthree="No Repayment Charges"
        featurefour={doorstep}
        benifitfour="On Time Disbursal"
      />

      <LoanDetail
        colonerowone="Max Loan Amount"
        coltworowone="1 Crore"
        colonerowtwo="Max Loan Tenure"
        coltworowtwo="Tenure from 12 months to 84 months"
        colonerowthree="Interest Rate"
        coltworowthree="Starting from 13% p.a"
        colonerowfour="Processing Fees"
        coltworowfour="Upto 2% of Loan Amount + GST"
      />
      <InformationLinks />
      <Eligibility />
      <WhyBankFinconPersonal />
      <Documentation />
      <Footer />
    </div>
  );
}

export default PersonalLoan;
