import React from "react";
import cash from "../assets/icons/Cash.png";
import "./Services.css";
import Card from "./Card";
import houseLoan from "../assets/icons/houseLoan.png";
import propertyAgainstLoan from "../assets/icons/loanAgainstProperty.png";
import personalLoan from "../assets/icons/personalLoan.png";
import goldLoan from "../assets/icons/goldLoan.png";
import bussinessLoan from "../assets/icons/bussinessLoan.png";
import carLoan from "../assets/icons/carLoan.png";
import educationLoan from "../assets/icons/educationLoan.png";
import creditCard from "../assets/icons/creditCard.png";

function Services() {
  

  
  return (
    <>
    <section id="service">
      <div className="service-container" >
        <h1 id="left-marg">
          Our Services
          <span>
            <img src={cash} alt="" />
          </span>
        </h1>
        <p id="left-marg">We provide wide range of Loans and Insurance for our Customer’s</p><br />
        <center>
        <div className="holder">

          <div className="service-catalogue-one">
            <Card
              image={houseLoan}
              title="Home Loan"
              desc="Own your dream home with our easy and affordable home loans."
              redirectPath="/homeloan#homeloan"
            />
            <Card
              image={personalLoan}
              title="Personal Loan"
              desc="Get quick personal loans with flexible terms to meet your financial needs."
               redirectPath="/personalloan"
            />
            <Card
              image={bussinessLoan}
              title="Business Loan"
              desc="Empower your business with flexible loans and competitive interest rates."
               redirectPath="/businessloan"
            />
            <Card
              image={propertyAgainstLoan}
              title="Loan Against Property"
              desc="Unlock your property's value with affordable loans and flexible terms."
               redirectPath="/loanagainstproperty"
            />
            
          </div>
          <div className="service-catalogue-two">
          <Card
              image={carLoan}
              title="Car Loan"
              desc="Drive your dream car with our easy and affordable car loan options."
               redirectPath="/carloan"
            />
            <Card
              image={goldLoan}
              title="Gold Loan"
              desc="Get instant funds by unlocking the value of your gold with our gold loans."
               redirectPath="/loan#loan"
            />
            <Card
              image={educationLoan}
              title="Education Loan"
              desc="Invest in your future with our flexible and affordable education loans."
               redirectPath="/loan#educationloan"
            />
            <Card
              image={creditCard}
              title="Credit Card"
              desc="Enjoy financial freedom with our secure and rewarding credit cards."
               redirectPath="/loan#creditcard"
            />
          </div>
          <button className="btn-view-more" >Learn More+</button>
        </div>
        
        </center>
      </div></section>
      
    </>
  );
}

export default Services;
