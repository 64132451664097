import React from "react";
import "./AboutPage.css";
import WhatMakesUsSpecialAbout from "../Components/WhatMakesUsSpecialAbout";
import MessageFromFounder from "../Components/MessageFromFounder";
import OurServicesAbout from "../Components/OurServicesAbout";
// import OurLenderAbout from "../Components/OurLenderAbout";
import GetInTouch from "../Components/GetInTouch";
import Navbar from "../Components/Navbar";
import AboutUsInfo from "../Components/AboutUsInfo";
import Lender from "../Components/Lender";
import Footer from "../Components/Footer";


function AboutPage() {
  return (
    <div>
      <Navbar />
      <AboutUsInfo />
      <WhatMakesUsSpecialAbout />
      <MessageFromFounder />
      <OurServicesAbout />
      <Lender/>
      <GetInTouch />
      <Footer/>
    </div>
  );
}

export default AboutPage;
