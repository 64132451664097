import React from 'react';
import { useNavigate } from 'react-router-dom';

const Card = (prop) => {
    const navigate = useNavigate();

    const handleClick = () => {
        if (prop.redirectPath) { // Access redirectPath from prop
            navigate(prop.redirectPath);
        }
    };

    return (
        <>
            <div className="outerbox">
                <center>
                    <img id="coordinate" src={prop.image} alt="" />
                    <br /><br />
                    <h3 id="coordinate">{prop.title}</h3>
                    <p id="coordinate">{prop.desc}</p>
                    <button onClick={handleClick} id='outerbox-button'>Learn More+</button>
                </center>
            </div>
        </>
    );
};

export default Card;
