import React from 'react'
import Navbar from '../Components/Navbar'
import carloanbanner from "../assets/carloanbanner.jpg"
import "./CarLoan.css"
import interest from "../assets/interest.png";
import hasslefree from "../assets/hasselfree.png";
import norepayment from "../assets/norepayment.png";
import doorstep from "../assets/doorstep.png";
import FeatureAndBenifit from '../Components/FeatureAndBenifit';
import LoanDetail from '../Components/LoanDetail';
import InformationLinks from '../Components/InformationLinks';
import Eligibility from '../Components/Eligibility';

import Documentation from '../Components/Documentation';
import Footer from '../Components/Footer';
import Introduction from '../Components/Introduction';
import carloanimage from"../assets/carloanimage.jpg"
import WhyBankFinconCarLoan from '../Components/WhyBankFinconCar';
import EMICalculator from '../Components/EMICalculator';

function CarLoan() {
  return (
    <div>
      <Navbar/>
      <p className="link-address">
        <a href="/">Home</a>
        &nbsp;{">>>"}&nbsp;
        <a href="/#service">Services</a>
        &nbsp;{">>>"}&nbsp;
        <a href="/homeloan">Business Loan</a>
      </p>
      <div className="banner-section-for-all">
        <div className="homeloan-line" id="carloan-line">
          <span>
            {" "}
            facing Cash Crunch? <br /> <br />
            <b>Car Loan</b> <br />
            <br /> Bank Fincon Offer Car Loan As Well!
          </span>

          <p>
            <button id="btn-primary-personal">
              <a
                href="https://wa.me/+919136043666"
                target="_blank"
                rel="noopener noreferrer"
              >
                Book a Call
              </a>
            </button>
          </p>
        </div>

        <img src={carloanbanner} alt="" />
      </div>
      <Introduction
        title="Car Loan"
        desc="A car loan is a type of financing provided by lenders to help individuals purchase a vehicle, typically requiring repayment with interest over a fixed tenure."
        roi="8+"
        tenure="7 Years"
        image={carloanimage}
/>
<EMICalculator/>





      <FeatureAndBenifit
        featureone={interest}
        benifitone="Simplified Access & Minimal Documentation"
        featuretwo={hasslefree}
        benifittwo="Hassle Free Transacation"
        featurethree={norepayment}
        benifitthree="Minimum Loan Amount Rs. 50,000"
        featurefour={doorstep}
        benifitfour="On Time Disbursal"
      />
      <LoanDetail
        colonerowone="Loan Amount"
        coltworowone="Upto 5 Cr"
        colonerowtwo="Loan Tenure"
        coltworowtwo="Up to 30 Yrs"
        colonerowthree="Floating Interest Rate %"
        coltworowthree="8.75%-10%"
        colonerowfour="Fixed Interest Rate %"
        coltworowfour="14%"
        colonerowfive="Processing Fees"
        coltworowfive="Upto 1% of Loan Amount + GST (minimum Rs.10000)"
      />
      <InformationLinks/>
    <Eligibility/>
    <WhyBankFinconCarLoan/>
    <Documentation/>
    <Footer/>
    </div>
  )
}

export default CarLoan
