import React from 'react'
import"../Components/Introduction.css"
import experience from "../assets/icons/30.png"
import doc from "../assets/icons/Document.png"


function Introduction(prop) {
  return (
    <>
    <div>
        <div className="introduction-container">
        
            <div className="left-intro-half">
            <span>Bank Fincon</span>
            <br />
            <br />
            <h2>{prop.title}</h2>
            <br /><br />
            <p>{prop.desc}</p><br /><br />

            <div className="feature-min">
                <div className="feature-min-item">
                    <span>{prop.roi}</span>
                    <p>Attractive Interest Rate</p>
                </div>
                <div className="feature-min-item">
                    <span><img src={doc} alt="" srcset="" /></span>
                    <p>Minimal Documentation</p>
                </div>
                <div className="feature-min-item">
                    <span><img src={experience} alt="" srcset="" /></span>
                    <p>{prop.tenure}  of Tenure</p>
                </div>
            </div>
            </div>
            <div className="right-intro-half">
                <img src={prop.image} alt="" />
            </div>
        </div></div>
    </>
  )
}

export default Introduction
