import React from "react";

import "./CombinedLoans.css"
import Introduction from "../Components/Introduction";
import Navbar from "../Components/Navbar";
import lowinterest from "../assets/interest.png";
import fastapproval from "../assets/hasselfree.png";
import flexirepayment from "../assets/norepayment.png";
import secureloan from "../assets/doorstep.png";
import goldloanimage from "../assets/icons/goldloanimagetwo.jpg";
import FeatureAndBenifit from "../Components/FeatureAndBenifit";
import LoanDetail from "../Components/LoanDetail";
// import Eligibility from "../Components/Eligibility";
// import Documentation from "../Components/Documentation";
import educationloanimage from "../assets/educationloanimagetwo.jpg";
import creditcardimage from "../assets/creditcardimageone.jpg";
import Footer from "../Components/Footer";
import WhyBankFincongold from "../Components/WhyBankFincongold";
import WhyBankFinconEducation from "../Components/WhyBankFinconEducation";
import WhyBankFinconCredit from "../Components/WhyBankFinconCredit";
import EMICalculator from "../Components/EMICalculator";
function CombinedLoans() {
  return (
    <>
    <div id="loan">
      <Navbar />

      <center>
        <div className="quick-access">
        <span >
          <a href="/loan#goldloan">Gold Loan</a>
          <a href="/loan#educationloan">Education Loan</a>
          <a href="/loan#creditcard">Credit Card</a>
        </span> </div>
      </center>
     

      <div id="goldloan">
        <p className="link-address">
          <a href="/">Home</a>&nbsp;{">>>"}&nbsp;
          <a href="/#service">Services</a>
          &nbsp;{">>>"}&nbsp;<a href="/loan#goldloan">Gold Loan</a>
        </p>
        <Introduction
          title="Gold Loan"
          desc="A Gold Loan allows you to borrow funds by pledging your gold ornaments as collateral. It's a quick and easy way to access funds for personal or business needs with minimal documentation and attractive interest rates."
          roi="7+"
          tenure="36 Months"
          image={goldloanimage}
        /><EMICalculator/>
        <FeatureAndBenifit
          featureone={lowinterest}
          benifitone="Low Interest Rates"
          featuretwo={fastapproval}
          benifittwo="Quick Loan Approval"
          featurethree={flexirepayment}
          benifitthree="Flexible Repayment Options"
          featurefour={secureloan}
          benifitfour="Safe and Secure Process"
        />
        <LoanDetail
          colonerowone="Max Loan Amount"
          coltworowone="Up to 75% of Gold Value"
          colonerowtwo="Max Loan Tenure"
          coltworowtwo="Tenure from 3 months to 36 months"
          colonerowthree="Interest Rate"
          coltworowthree="Starting from 7% p.a"
          colonerowfour="Processing Fees"
          coltworowfour="Upto 1% of Loan Amount + GST"
        />{" "}
        <WhyBankFincongold />
      </div>

      <div id="educationloan">
        <br />
        <br />
        <br />
        <p className="link-address">
          <a href="/">Home</a>&nbsp;{">>>"}&nbsp;
          <a href="/#service">Services</a>
          &nbsp;{">>>"}&nbsp;<a href="/loan#educationloan">Education Loan</a>
        </p>
        <Introduction
          title="Education Loan"
          desc="An Education Loan is designed to support students financially for their higher education in India or abroad. It covers tuition fees, living expenses, and other associated costs, helping you achieve your academic goals stress-free."
          roi="9+"
          tenure="15 Years"
          image={educationloanimage}
        /><EMICalculator/>
        <FeatureAndBenifit
          featureone={lowinterest}
          benifitone="Covers Full Education Cost"
          featuretwo={fastapproval}
          benifittwo="Low EMIs with Long Tenure"
          featurethree={secureloan}
          benifitthree="Tax Benefits Under Section 80E"
          featurefour={flexirepayment}
          benifitfour="No Collateral for Certain Amounts"
        />
        <LoanDetail
          colonerowone="Max Loan Amount"
          coltworowone="Up to ₹50 Lakhs (Domestic) & ₹1 Crore (Abroad)"
          colonerowtwo="Max Loan Tenure"
          coltworowtwo="Tenure up to 15 years after course completion"
          colonerowthree="Interest Rate"
          coltworowthree="Starting from 9% p.a"
          colonerowfour="Processing Fees"
          coltworowfour="Up to ₹10,000 + GST"
        />

        <WhyBankFinconEducation />
      </div>

      <div id="creditcard">
        <br />
        <br />
        <br />
        <p className="link-address">
          <a href="/">Home</a>&nbsp;{">>>"}&nbsp;
          <a href="/#service">Services</a>
          &nbsp;{">>>"}&nbsp;<a href="/loan#creditcard">Credit Card</a>
        </p>
        <Introduction
          title="Credit Card"
          desc="A Credit Card allows you to make purchases, pay bills, and access funds with ease. Enjoy rewards, cashback, and a host of benefits while managing your expenses efficiently."
          roi="%"
          tenure="Monthly Billing Cycle"
          image={creditcardimage}
        /><EMICalculator/>
        <FeatureAndBenifit
          featureone={lowinterest}
          benifitone="Earn Rewards on Every Spend"
          featuretwo={fastapproval}
          benifittwo="Exciting Cashback Offers"
          featurethree={flexirepayment}
          benifitthree="Low Annual Fees"
          featurefour={secureloan}
          benifitfour="Worldwide Acceptance"
        />
        <LoanDetail
          colonerowone="Credit Limit"
          coltworowone="Up to ₹10 Lakhs"
          colonerowtwo="Interest-Free Period"
          coltworowtwo="Up to 50 Days"
          colonerowthree="Interest Rate"
          coltworowthree="Starts at 3.5% per month"
          colonerowfour="Annual Fees"
          coltworowfour="Starting from ₹499 + GST"
        />
        <WhyBankFinconCredit />
      </div>

      <Footer />
      </div></>
  );
}

export default CombinedLoans;
