import React from 'react'

import Navbar from '../Components/Navbar'
import OurServices from '../Components/OurServicesAbout'

function ServicesPage() {
  return (
    <div>
        <Navbar/>
      <OurServices/>
    </div>
  )
}

export default ServicesPage
