import React from "react";
import "../Components/LoanDetail.css";
function LoanDetail(prop) {
  return (
    <div>
      <p id="headingpara">Loan Detail</p>
      <table>
        <tr>
          <th>
            {prop.colonerowone}
          </th>
          <td>{prop.coltworowone}</td>
        </tr>
        <tr>
          <th>
            {prop.colonerowtwo}
          </th>
          <td>{prop.coltworowtwo}</td>
        </tr>
        <tr>
          <th>
            {prop.colonerowthree}
          </th>
          <td>{prop.coltworowthree}</td>
        </tr>
        <tr>
          <th>
            {prop.colonerowfour}
          </th>
          <td>{prop.coltworowfour}</td>
        </tr>
        <tr>
          <th>
            {prop.colonerowfive}
          </th>
          <td>{prop.coltworowfive}</td>
        </tr>
      </table>
    </div>
  );
}

export default LoanDetail;
