import React from 'react'
import "./Label.css"
import india from "../assets/icons/India.png"
import insight from "../assets/icons/CustomerInsight.png"
import businessman from "../assets/icons/Businessman.png"
import burst from "../assets/icons/Bursts.png"
import refer from "../assets/icons/refer.png"
import register from "../assets/icons/Registration.png"


function Label() {
  return (
    <>
        <div className="labelContainer">
            
            <div className="Label-box">
            <div className="containthis"><img src={india} alt="" /></div>
            <div className="containthat"><h3>Pan India Service</h3>
            <p>We ensures seamless financial services with PAN-India availability, connecting every corner with trust and reliability. Wherever you are, we're here for you!</p></div></div>


            <div className="Label-box">
            <div className="containthis"><img src={insight} alt="" /></div>
            <div className="containthat"><h3>12 yrs Experience</h3>
            <p>With 12 years of expertise in the finance sector, BankFinCon delivers trusted solutions tailored to your financial needs. Experience you can rely on, results you can trust!</p></div></div>


            <div className="Label-box">
            <div className="containthis"><img src={businessman} alt="" /></div>
            <div className="containthat"><h3>Be Your Own Boss</h3>
            <p>Take charge of your future with Bank Fincon — empowering you to be your own boss. Step into independence and unlock limitless opportunities!</p></div></div>



            <div className="Label-box">
            <div className="containthis"><img src={burst} alt="" /></div>
            <div className="containthat"><h3>Multiple Service</h3>
            <p>We offers a range of services, including insurance, loans, and expert financial consultation — all under one roof. Your one-stop solution for all financial needs!</p></div></div>


            <div className="Label-box">
            <div className="containthis"><img src={register} alt="" /></div>
            <div className="containthat"><h3>Easy Process </h3>
            <p>Experience hassle-free and seamless transactions with Bank Fincon — where convenience meets reliability every step of the way!</p></div></div>



            <div className="Label-box">
            <div className="containthis"><img src={refer} alt="" /></div>
            <div className="containthat"><h3>Refer and Earn</h3>
            <p>Share the benefits of BankFinCon with friends and family—refer and earn exciting rewards effortlessly!</p></div></div>

        </div>
    </>
  )
}

export default Label
