import React from 'react'
import "./InformationLinks.css"

function InformationLinks() {
  return (
    <div>
        <center>
      <div className="information-links-container">
      
        <div className="information-links">
           
            <p><a href='#Eligibility'>Check Eligibility</a></p>
            <p><a href='#Whyus'>Why US!</a></p>
            <p><a href='#Documentation'>Documentation</a></p>
            <p><a href='#FAQ'>FAQ's</a></p>

        </div>
      </div></center>
    </div>
  )
}

export default InformationLinks
