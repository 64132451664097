import React from "react";
import homeloan from "../assets/loanimage.png";
import "./WhyBankFincon.css";
function WhyBankFincon() {
  return (
    <div>
      <div id="Whyus" className="why-bank-fincon-container">
        <div className="why-us-container-left">
          <h2 id="h2-heading">Features and Benefits of Our Home Loan</h2>
          <br />
          <p>
            Our home loan offerings cater to a variety of needs with competitive
            interest rates and affordable EMIs, making it easier for you to
            achieve your dream of owning or improving a home. <br /><br /><b>1. Home
            Construction Loan</b> <br />Ideal for those who own a plot of land but need
            financial assistance to construct a home. This loan also supports
            major repairs or renovations to an existing home. <br /><b>2. Home Extension
            Loan</b> <br /> Designed for homeowners who wish to expand their living space.
            Covers expenses for adding new rooms, building another floor, or
            constructing an annexe. <br /><b>3. Home Improvement Loan </b><br />Perfect for
            upgrading your home for a better lifestyle. Funds improvements such
            as replacing old furniture, upgrading fixtures, or fixing faulty
            lighting systems. <br /><b>4.NRI Home Loan</b> <br /> Tailored for Non-Resident Indians
            (NRIs) who wish to purchase residential property in India. While
            application formalities may differ slightly, this loan makes owning
            property in India accessible for NRIs. <br /> <br />These specialized loan
            products ensure that all your housing needs are met seamlessly,
            whether it's building, expanding, or upgrading your home.
          </p>
        </div>
        <img id="loanimg" src={homeloan} width="300px" height="300px"alt="" />
      </div>
    </div>
  );
}

export default WhyBankFincon;
