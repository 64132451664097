import React from "react";
import "./OurServicesAbout.css";
import personalloan from "../assets/icons/personalLoan.png";
import homeloan from "../assets/icons/houseLoan.png";
import businessloan from "../assets/icons/bussinessLoan.png";
import goldloan from "../assets/icons/goldLoan.png";
import educationloan from "../assets/icons/educationLoan.png";
import loanagainstproperty from "../assets/icons/loanAgainstProperty.png";
import supplychain from "../assets/Coins.png";
import carloan from "../assets/icons/carLoan.png";

import carinsurance from "../assets/protection.png";
import healthinsurance from "../assets/health-insurance.png";
import lifeinsurance from "../assets/healthcare.png";
import termlifeinsurance from "../assets/pension-term-assurance.png";
import mortgageinsurance from "../assets/broker.png";
import endowmentinsurance from "../assets/scholarship.png";
import criticalillinessinsurance from "../assets/icons/carLoan.png";
import travelinsurance from "../assets/travel-insurance.png";

import cashcard from "../assets/payment-method.png";
import debtconsolidation from "../assets/consolidation.png";
import creditcard from "../assets/icons/creditCard.png";
import billdiscount from "../assets/bill.png";
import overdraft from "../assets/Document.png";
import { useNavigate } from "react-router-dom";

const OurServices = () => {
  const navigate = useNavigate();

  return (
    <div className="services-section">
      <h2 id="ser-heading">Our Services</h2>
      <p>We provide a wide range of Loans and Insurance for our Customers</p>

      <section className="services">
        {/* Loans Section */}
        <div className="service-box">
          <div className="header-box">Loans</div>
          <div className="content-box">
            <div className="service-item">
              <img src={personalloan} alt="Loan Icon" className="service-icon" />
              <p>Personal Loan</p>
              <button
              
                className="service-btn"
                onClick={() => navigate("/personalloan")}
              >
                View
              </button>
            </div>

            <div className="service-item">
              <img src={homeloan} alt="Loan Icon" className="service-icon" />
              <p>Home Loan</p>
              <button
                className="service-btn"
                onClick={() => navigate("/homeloan")}
              >
                View
              </button>
            </div>

            <div className="service-item">
              <img src={businessloan} alt="Loan Icon" className="service-icon" />
              <p>Business Loan</p>
              <button
                className="service-btn"
                onClick={() => navigate("/businessloan")}
              >
                View
              </button>
            </div>

            <div className="service-item">
              <img
                src={loanagainstproperty}
                alt="Loan Icon"
                className="service-icon"
              />
              <p>Loan Against Property</p>
              <button
                className="service-btn"
                onClick={() => navigate("/loanagainstproperty")}
              >
                View
              </button>
            </div>

            <div className="service-item">
              <img src={carloan} alt="Loan Icon" className="service-icon" />
              <p>Auto Loan</p>
              <button
                className="service-btn"
                onClick={() => navigate("/carloan")}
              >
                View
              </button>
            </div>

            <div className="service-item">
              <img
                src={educationloan}
                alt="Loan Icon"
                className="service-icon"
              />
              <p>Education Loan</p>
              <button
                className="service-btn"
                onClick={() => navigate("/loan")}
              >
                View
              </button>
            </div>

            <div className="service-item">
              <img src={supplychain} alt="Loan Icon" className="service-icon" />
              <p>Supply Chain</p>
              <button
                className="service-btn"
                onClick={() => navigate("/loan")}
              >
                View
              </button>
            </div>

            <div className="service-item">
              <img src={goldloan} alt="Loan Icon" className="service-icon" />
              <p>Gold Loan</p>
              <button
                className="service-btn"
                onClick={() => navigate("/loan")}
              >
                View
              </button>
            </div>
          </div>
        </div>

        {/* Insurance Section */}
        <div className="service-box">
          <div className="header-box">Insurance</div>
          <div className="content-box">
            <div className="service-item">
              <img
                src={healthinsurance}
                alt="Insurance Icon"
                className="service-icon"
              />
              <p>Health Insurance</p>
              <button
                className="service-btn"
                onClick={() => navigate("/healthinsurance")}
              >
                View
              </button>
            </div>

            <div className="service-item">
              <img
                src={lifeinsurance}
                alt="Insurance Icon"
                className="service-icon"
              />
              <p>Life Insurance</p>
              <button
                className="service-btn"
                onClick={() => navigate("/lifeinsurance")}
              >
                View
              </button>
            </div>

            <div className="service-item">
              <img
                src={carinsurance}
                alt="Insurance Icon"
                className="service-icon"
              />
              <p>Auto Insurance</p>
              <button
                className="service-btn"
                onClick={() => navigate("/autoinsurance")}
              >
                View
              </button>
            </div>

            <div className="service-item">
              <img
                src={travelinsurance}
                alt="Insurance Icon"
                className="service-icon"
              />
              <p>Travel Insurance</p>
              <button
                className="service-btn"
                onClick={() => navigate("/travelinsurance")}
              >
                View
              </button>
            </div>

            <div className="service-item">
              <img
                src={termlifeinsurance}
                alt="Insurance Icon"
                className="service-icon"
              />
              <p>Term Life Insurance</p>
              <button
                className="service-btn"
                onClick={() => navigate("/termlifeinsurance")}
              >
                View
              </button>
            </div>

            <div className="service-item">
              <img
                src={mortgageinsurance}
                alt="Insurance Icon"
                className="service-icon"
              />
              <p>Mortgage Insurance</p>
              <button
                className="service-btn"
                onClick={() => navigate("/mortgageinsurance")}
              >
                View
              </button>
            </div>

            <div className="service-item">
              <img
                src={criticalillinessinsurance}
                alt="Insurance Icon"
                className="service-icon"
              />
              <p>Critical Illness Insurance</p>
              <button
                className="service-btn"
                onClick={() => navigate("/criticalillnessinsurance")}
              >
                View
              </button>
            </div>

            <div className="service-item">
              <img
                src={endowmentinsurance}
                alt="Insurance Icon"
                className="service-icon"
              />
              <p>Endowment Insurance</p>
              <button
                className="service-btn"
                onClick={() => navigate("/endowmentinsurance")}
              >
                View
              </button>
            </div>
          </div>
        </div>

        {/* Others Section */}
        <div className="service-box">
          <div className="header-box">Others</div>
          <div className="content-box">
            <div className="service-item">
              <img src={cashcard} alt="Other Icon" className="service-icon" />
              <p>Cash Card</p>
              <button
                className="service-btn"
                onClick={() => navigate("/cashcard")}
              >
                View
              </button>
            </div>

            <div className="service-item">
              <img
                src={debtconsolidation}
                alt="Other Icon"
                className="service-icon"
              />
              <p>Debt Consolidation</p>
              <button
                className="service-btn"
                onClick={() => navigate("/debtconsolidation")}
              >
                View
              </button>
            </div>

            <div className="service-item">
              <img src={overdraft} alt="Other Icon" className="service-icon" />
              <p>OverDraft</p>
              <button
                className="service-btn"
                onClick={() => navigate("/overdraft")}
              >
                View
              </button>
            </div>

            <div className="service-item">
              <img src={creditcard} alt="Other Icon" className="service-icon" />
              <p>Credit Card</p>
              <button
                className="service-btn"
                onClick={() => navigate("/creditcard")}
              >
                View
              </button>
            </div>

            <div className="service-item">
              <img
                src={billdiscount}
                alt="Other Icon"
                className="service-icon"
              />
              <p>Bill Discounting</p>
              <button
                className="service-btn"
                onClick={() => navigate("/billdiscounting")}
              >
                View
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default OurServices;
