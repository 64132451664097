import React, { useState, useEffect } from "react";
import "./HeroSection.css";
import logo from "../assets/logo.png";
import loan from "../assets/loanimage.png";
import insurance from "../assets/insurance.png";
import { Link } from "react-router-dom";


const slides = [
  {
    heading: "Simplify Your Financial Goals",
    buttonLabel: "Explore Services",
    image: logo,
  },
  {
    heading: "Secure Banking, Anytime, Anywhere",
    buttonLabel: "Get Started",
    image: loan,
  },
  {
    heading: "Plan Your Future with Confidence",
    buttonLabel: "Learn More",
    image: insurance,
  },
];

function HeroSection() {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % slides.length);
    }, 5000); // Change slide every 5 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  return (
    <div>
      <br /><br /><br />
    <div className="hero-carousel">
      
      <div className="carousel-content">
        <div className="text-content">
          <h1>{slides[currentSlide].heading}</h1>
          <h1>With Bank Fincon</h1>
          <button className="hero-button"><Link to="/services">{slides[currentSlide].buttonLabel}</Link></button>
        </div>
        <div className="image-content">
          <img src={slides[currentSlide].image} alt="Slide Visual" />
        </div>
      </div>

      {/* Carousel Indicators */}
      <div className="carousel-indicators">
        {slides.map((_, index) => (
          <span
            key={index}
            className={`dot ${index === currentSlide ? "active" : ""}`}
            onClick={() => setCurrentSlide(index)} // Optional: Allow dot clicks to change slides
          ></span>
        ))}
      </div>
    </div></div>
  );
}

export default HeroSection;
