import React from 'react'
import goldLoanImage from "../assets/goldbiscuit.jpg"

function WhyBankFincongold() {
  return (
    <div>
      <div>
        <div id="Whyus" className="why-bank-fincon-container">
          <div className="why-us-container-left">
            <h2 id="h2-heading">Features and Benefits of Gold Loan</h2>
            <br />
            <p>
              Our Gold Loan is designed to help you unlock the value of your gold assets to meet urgent financial needs. Here are some of its key features and benefits: <br /><br />
              
              <b>1. High Loan Amount</b> <br />
              Avail up to 75% of the gold's market value, ensuring substantial funds for personal or business purposes. <br /><br />
              
              <b>2. Competitive Interest Rates</b> <br />
              Enjoy attractive and competitive interest rates, making repayment affordable. <br /><br />
              
              <b>3. Quick Processing</b> <br />
              Experience fast approvals with minimal documentation, ensuring instant access to funds. <br /><br />
              
              <b>4. Flexible Tenure</b> <br />
              Choose a repayment tenure that suits your needs, ranging from 3 months to 36 months. <br /><br />
              
              <b>5. Multipurpose Usage</b> <br />
              Use the loan amount for diverse needs such as education, weddings, medical emergencies, or business expansion. <br /><br />
              
              <b>6. Safety and Security</b> <br />
              Your gold remains safe and secure in our custody until full repayment of the loan. <br /><br />
              
              Our Gold Loan ensures you can meet your financial goals without selling your precious gold, giving you financial flexibility and peace of mind.
            </p>
          </div>
          <img id="loanimg" src={goldLoanImage} width="300px" height="300px" alt="Gold Loan" />
        </div>
      </div>
    </div>
  )
}

export default WhyBankFincongold
