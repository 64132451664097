import React from 'react';
import "./WhyBankFincon.css";
import educationLoanImage from "../assets/educationloanimagethree.jpg";

function WhyBankFinconEducationLoan() {
  return (
    <div>
      <div>
        <div id="Whyus" className="why-bank-fincon-container">
          <div className="why-us-container-left">
            <h2 id="h2-heading">Features and Benefits of Education Loan</h2>
            <br />
            <p>
              Our Education Loan is designed to make achieving your academic dreams affordable and hassle-free. Here are some of its key features and benefits: <br /><br />
              
              <b>1. High Loan Amount</b> <br />
              Get financing for up to 100% of your tuition fees and other related expenses, ensuring uninterrupted education. <br /><br />
              
              <b>2. Competitive Interest Rates</b> <br />
              Enjoy low-interest rates to make repayment stress-free for students and their families. <br /><br />
              
              <b>3. Flexible Repayment Options</b> <br />
              Start your repayment after completing your course or when you secure employment. <br /><br />
              
              <b>4. Coverage for Various Expenses</b> <br />
              Covers tuition fees, accommodation, books, travel, and other related costs for domestic and international studies. <br /><br />
              
              <b>5. Minimal Documentation</b> <br />
              Simple eligibility criteria and minimal paperwork to make the process quick and seamless. <br /><br />
              
              <b>6. Tax Benefits</b> <br />
              Avail tax benefits under Section 80E of the Income Tax Act for the interest paid on your education loan. <br /><br />
              
              Our Education Loan ensures that finances do not stand in the way of your educational aspirations, empowering you to build a brighter future.
            </p>
          </div>
          <img id="loanimg" src={educationLoanImage} width="300px" height="300px" alt="Education Loan" />
        </div>
      </div>
    </div>
  );
}

export default WhyBankFinconEducationLoan;
