import React from 'react'
import Navbar from '../Components/Navbar'
import homeloanbanner from '../assets/homeloanbanner.jpg'
import "./HomeLoan.css"
import Introduction from '../Components/Introduction'
import homelonefamily from "../assets/homeloanfamily.jpg"
import FeatureAndBenifit from '../Components/FeatureAndBenifit'
import interest from "../assets/interest.png"
import hasslefree from"../assets/hasselfree.png"
import norepayment from "../assets/norepayment.png"
import doorstep from "../assets/doorstep.png"
import LoanDetail from '../Components/LoanDetail'
import InformationLinks from '../Components/InformationLinks'
import Eligibility from '../Components/Eligibility'
import WhyBankFincon from '../Components/WhyBankFincon'
import Footer from "../Components/Footer";
import Documentation from '../Components/Documentation'
import EMICalculator from '../Components/EMICalculator'



function HomeLoan() {
  return (
    <div>
      <Navbar/>
      
      <p className="link-address" id='homeloan'><a href="/">Home</a>&nbsp;{'>>>'}&nbsp;<a href="/#service">Services</a>&nbsp;{'>>>'}&nbsp;<a href="/homeloan">HomeLoan</a></p>
      <div className="banner-section-for-all" >
       <div className="homeloan-line"><span> Turn your dream home ❤️ into reality with our easy and affordable <strong> home loans!</strong></span>
       
       <p><button className='btn-primary'><a href="https://wa.me/+919136043666" target="_blank" rel="noopener noreferrer">Book a Call</a></button></p></div>
       
      <img src={homeloanbanner}  alt="" /></div>
      
      <Introduction
        title="Home Loan"
        desc="A home loan is a financial product that helps individuals purchase or construct a house by borrowing funds from a lender, such as a bank or a financial institution. The borrower repays the loan over an agreed-upon tenure through Equated Monthly Installments (EMIs), which include both the principal amount and the interest."
        roi="%"
        tenure="30 Years"
        image={homelonefamily}
      /><EMICalculator/>
     
      <FeatureAndBenifit
        featureone={interest}
        benifitone="Attractive Rate Of Interest"
        featuretwo={hasslefree}
        benifittwo="Hassle Free Transacation"
        featurethree={norepayment}
        benifitthree="No Repayment Charges"
        featurefour={doorstep}
        benifitfour="On Time Disbursal"
      />
      <LoanDetail
      colonerowone= "Loan Amount"
      coltworowone= "Upto 5 Cr"
      colonerowtwo= "Loan Tenure"
      coltworowtwo= "Up to 30 Yrs"
      colonerowthree= "Floating Interest Rate %"
      coltworowthree= "8.75%-10%"
      colonerowfour= "Fixed Interest Rate %"
      coltworowfour= "14%"
      colonerowfive= "Processing Fees"
      coltworowfive= "Upto 1% of Loan Amount + GST (minimum Rs.10000)"/>

    <InformationLinks/>
    <Eligibility/>
    <WhyBankFincon/>
    <Documentation/>
    <Footer/>

    </div>


  )
}

export default HomeLoan
