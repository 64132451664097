import React from 'react';
import "./WhyBankFincon.css";
import carLoanImage from "../assets/carloanimagetwo.png";

function WhyBankFinconCarLoan() {
  return (
    <div>
      <div>
        <div id="Whyus" className="why-bank-fincon-container">
          <div className="why-us-container-left">
            <h2 id="h2-heading">Features and Benefits of Car Loan</h2>
            <br />
            <p>
              Our Car Loan is designed to make owning your dream car easy and affordable. Here are some of its key features and benefits: <br /><br />
              
              <b>1. High Loan Amount</b> <br />
              Get financing for up to 90% or more of the car's on-road price, ensuring minimal upfront payment. <br /><br />
              
              <b>2. Competitive Interest Rates</b> <br />
              Benefit from low-interest rates, making your EMIs budget-friendly and manageable. <br /><br />
              
              <b>3. Quick Processing</b> <br />
              Experience hassle-free processing with minimal documentation and swift approvals. <br /><br />
              
              <b>4. Flexible Tenure</b> <br />
              Choose repayment tenures of up to 7 years, giving you the flexibility to plan your finances. <br /><br />
              
              <b>5. Easy Eligibility</b> <br />
              Loans available for salaried, self-employed individuals, and professionals with simple eligibility criteria. <br /><br />
              
              <b>6. Pre-Owned Cars</b> <br />
              Financing options are also available for pre-owned cars, making owning any car a reality. <br /><br />
              
              Our Car Loan ensures you hit the road in your dream car without any financial worries, giving you convenience and freedom.
            </p>
          </div>
          <img id="loanimg" src={carLoanImage} width="300px" height="300px" alt="Car Loan" />
        </div>
      </div>
    </div>
  );
}

export default WhyBankFinconCarLoan;
