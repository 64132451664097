import React from "react";

import "./Statistics.css";
import experienceImage from "../assets/experienceImage.png";
import partnerBank from "../assets/partnerBank.png"
import branchAcross from "../assets/branchAcross.png"
import disbursal from "../assets/disbursal.png"
function Statistics() {
  return (
    <>
      <div className="statistic-container" id="statistics">
        <center>
          <h1 >Bank Fincon✨</h1>
          <p>Driving Success, Building Trust</p>
          <div className="experienceSection">
            <div className="experienceContent">
              <img src={experienceImage} alt="" />
              <h2 id="experienceText">12+</h2>
              <p id="exception">Years of Experience </p>
            </div>
            <div className="experienceContent">
              <img src={partnerBank} alt="" />
              <h2 id="partnerText">19+</h2>
              <p id="exception">Total Lending Partner </p>
            </div>
            <div className="experienceContent">
              <img src={branchAcross} alt="" />
              <h2 id="branchText">100%</h2>
              <p id="exception">Customer Satisfaction </p>
            </div>
            <div className="experienceContent">
              <img src={disbursal} alt="" />
              <h2 id="disbursalText">🤝</h2>
              <p id="exception">Successful Disbursal </p>
            </div>
            
          </div>
          
        </center>
        
      </div>
    </>
  );
}

export default Statistics;
