import React, { useState } from 'react';
import "./Form.css";
import questions from "../assets/queries.png";

function Form() {
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://www.bankfincon.com/submit-form', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        alert('Form submitted successfully');
      } else {
        alert('Error submitting the form');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Error submitting the form');
    }
  };

  return (
    <>
      <div className="form-container">
        <div className="form-left">
          <img src={questions} alt="" />
        </div>
        <div className="form-right">
          <center><h1>For Enquiry</h1></center>
          <div className="formbold-main-wrapper">
            <div className="formbold-form-wrapper">
              <form onSubmit={handleSubmit}>
                <div className="formbold-input-flex">
                  <div>
                    <input
                      type="text"
                      name="firstname"
                      id="firstname"
                      value={formData.firstname}
                      onChange={handleChange}
                      placeholder="Jane"
                      className="formbold-form-input"
                    />
                    <label htmlFor="firstname" className="formbold-form-label">First name</label>
                  </div>
                  <div>
                    <input
                      type="text"
                      name="lastname"
                      id="lastname"
                      value={formData.lastname}
                      onChange={handleChange}
                      placeholder="Cooper"
                      className="formbold-form-input"
                    />
                    <label htmlFor="lastname" className="formbold-form-label">Last name</label>
                  </div>
                </div>

                <div className="formbold-input-flex">
                  <div>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="abc@gmail.com"
                      className="formbold-form-input"
                    />
                    <label htmlFor="email" className="formbold-form-label">Email</label>
                  </div>
                  <div>
                    <input
                      type="text"
                      name="phone"
                      id="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      placeholder="(+91) 555-0115"
                      className="formbold-form-input"
                    />
                    <label htmlFor="phone" className="formbold-form-label">Phone</label>
                  </div>
                </div>

                <div className="formbold-textarea">
                  <textarea
                    rows="6"
                    name="message"
                    id="message"
                    value={formData.message}
                    onChange={handleChange}
                    placeholder="Write your message..."
                    className="formbold-form-input"
                  ></textarea>
                  <label htmlFor="message" className="formbold-form-label">Message</label>
                </div>

                <button type="submit" className="formbold-btn">Send Message</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Form;
