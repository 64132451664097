import React from "react";
import "../Components/FeatureAndBenifit.css"

function FeatureAndBenifit(prop) {
  return (
    <div>
      <center>
        <h4>Feature and Benifits</h4>
      </center>
      <div className="feature-benifit">
        <center>
          <div className="feature-min">
            <div className="feature-min-item">
              <span><img src={prop.featureone}id="image-pos" width="70px" height="70px" alt="" srcset="" /></span>
              <p id="feature-min">{prop.benifitone}</p>
            </div>


            <div className="feature-min-item">
              <span>
                <img src={prop.featuretwo} id="image-pos" width="70px" height="70px"  alt="" srcset="" />
              </span>
              <p id="feature-min">{prop.benifittwo}</p>
            </div>
            <div className="feature-min-item">
              <span>
                <img src={prop.featurethree} id="image-pos" width="70px" height="70px"  alt="" srcset="" />
              </span>
              <p id="feature-min">{prop.benifitthree}</p>
            </div>
            <div className="feature-min-item">
              <span>
                <img src={prop.featurefour} id="image-pos" width="70px" height="70px"  alt="" srcset="" />
              </span>
              <p id="feature-min">{prop.benifitfour}</p>
            </div>
          </div>
        </center>
      </div>
    </div>
  );
}

export default FeatureAndBenifit;
