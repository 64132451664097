import React from "react";
import "./WhatMakesUsSpecialAbout.css";
import tenure from "../assets/84.png"
import handshake from "../assets/Handshake.png"
import sales from "../assets/TotalSales.png"
import customer from "../assets/Customer.png"



const WhatMakesUsSpecial = () => {
  return (
    <section className="mid-section">
      <h2 className="section-title" id="mid-title">What Makes Us Special?</h2>

      <div className="mid-container">

      <div className="mid-item" id="tenure">
        <h3>
          <img src={tenure} alt="Tenure" />
        </h3>
        <p>84+ Months of Tenure</p>
      </div>
      <div className="mid-item" id="relationships">
        <h3>
          <img src={handshake} alt="Strategic" />
        </h3>
        <p>Strategic Relationship Building</p>
      </div>
      <div className="mid-item" id="negotiation-skills">
        <h3>
          <img src={sales} alt="Negotiation" />
        </h3>
        <p>Safe & Negotiation Skills</p>
      </div>
      <div className="mid-item" id="customer-centric">
        <h3>
          <img src={customer} alt="Customer" />
        </h3>
        <p>Customer-Centric Approach</p>
      </div>

      </div>

    </section>

 
  );
};

export default WhatMakesUsSpecial;
