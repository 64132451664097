import React from 'react';
import icici from "../assets/icons/icci-bank-logo-01.png";
import chola from "../assets/Chola (1).png";
import yesbank from "../assets/icons/yes bank-01.png";
import shreeramfin from "../assets/icons/shreeramfinance.png";
import sbi from "../assets/icons/sbi-bank-logo-01.png";
import kotak from "../assets/icons/kotak-mahindra-bank-logo-01.png";
import iifl from "../assets/icons/IIFL Finance.png";
import icfs from "../assets/icons/ICSFS-Logo.svg";
import hdfc from "../assets/icons/HDFC Bank-01.png";
import federalbank from "../assets/icons/Federal Bank-01.png";
import bob from "../assets/icons/Bank of Baroda-01.png";
import axis from "../assets/icons/axisbank.png";
import bajaj from "../assets/Bajaj Finserv (1).png";
import pnb from "../assets/icons/pnbbank.png";
import tata from "../assets/tatacapital.png";
import aditya from "../assets/aditya.png";
import ugro from "../assets/ugro.png";
import herofin from "../assets/herofin.png";

import "./Lender.css";

function Lender() {
  return (
    <>
      <br />
      <br />
      <br />
      <div className="lender-heading">
        <center>
          <h1>Top Lenders that Bank Fincon Works With</h1>
        </center>
      </div>
      <div className="marquee-box">
        {/* eslint-disable-next-line jsx-a11y/no-distracting-elements */}
        <marquee behavior="scroll" direction="left">
          <img src={axis} alt="Axis Bank" />
          <img src={bajaj} alt="Bajaj Finserv" />
          <img src={bob} alt="Bank of Baroda" />
          <img src={chola} alt="Chola" />
          <img src={federalbank} alt="Federal Bank" />
          <img src={hdfc} alt="HDFC Bank" />
          <img src={icici} alt="ICICI Bank" />
          <img src={icfs} alt="ICFS" id="exception-pnb" />
          <img src={iifl} alt="IIFL Finance" />
          <img src={kotak} alt="Kotak Mahindra Bank" />
          <img src={sbi} alt="SBI Bank" />
          <img src={shreeramfin} alt="Shreeram Finance" />
          <img src={yesbank} alt="Yes Bank" />
          <img src={pnb} alt="PNB Bank" id="exception-pnb" />
          <img src={tata} alt="Tata Capital" id="custom-size" />
          <img src={aditya} alt="Aditya Birla Finance" id="custom-size" />
          <img src={ugro} alt="Ugro Capital" id="custom-size" />
          <img src={herofin} alt="Hero FinCorp" id="custom-size" />
        </marquee>
      </div>
    </>
  );
}

export default Lender;
