import React from "react";
import "./Eligibility.css";
function Eligibility() {
  return (
    <div>
        <center>
            <h3 id="Eligibility">Eligibility</h3>
      <div className="eligibility-card-container">
        <div className="eligibility-card-left">
          <h3>Eligibility Criteria For Salaried Individuals</h3>
          <p>
            <b>Age</b>:
            <br /> Must be between 21 and 58 years. Should not exceed the
            retirement age by the loan's end. <br />
            <b>Income</b>: <br />
            Minimum ₹15,000 per month <br />
            <b>Employment</b>: <br />
            At least 2-3 years of stable employment in an MNC, private, or
            public limited company. <br />
            <b>Documents</b>: <br />
            Proof of employment and financial stability is mandatory. <br />
            <b>Credit Score</b>: <br />A credit score of 750 or higher is
            considered favorable for loan approval.
          </p>
          <br />
        </div>
        <div className="eligibility-card-left" id="eligibility-card-right">
        <h3>Eligibility Criteria For Salaried Individuals</h3>
        <p>
        
            <b>Age</b>:
            <br /> Must be between 25 and 65 years.  <br />
            <b>Income</b>: <br />
            Minimum ₹2,00,000 per year, supported by consistent income proof via tax returns. <br />
            <b>Employment/Business Stability</b>: <br />
            Minimum 3 years of experience in the current field. <br />
            <b>Documents</b>: <br />
            Comprehensive financial records, tax returns, and proof of business stability are mandatory. <br />
            <b>Credit Score</b>: <br />A credit score of 750 or higher is
            considered favorable for loan approval.
          </p>
        </div>
      </div></center>
    </div>
  );
}

export default Eligibility;
