import React from "react";
import "./MessageFromFounder.css";

const Message = () => {
  return (
    <div class="skewed-box">
    <div class="content">
      <h2>Message from our Founder</h2>
      <p>
        Over the past 12 years, we have built a successful career as a Direct Sales Agent (DSA) helping clients access a wide range of financial products, including loans, credit cards, and various banking services. Throughout this journey, I have developed strong, long-lasting relationships with both financial institutions and customers, honing my sales, negotiation, and communication skills. My deep understanding of the financial industry has been instrumental in offering clients well-informed, personalized solutions.
      </p>
      <p>
        A core aspect of my success has been my ability to truly understand and assess each client's unique needs. This allows me to provide tailored financial solutions that align with their goals, ensuring a smooth and efficient process for both the client and the lender. My focus on customer satisfaction, combined with my in-depth knowledge of banking products, has contributed to consistently achieving sales targets and driving client loyalty.
      </p>
    </div>
  </div>
  
  );
};

export default Message;
