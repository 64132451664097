import React from "react";
import "./Footer.css";
import logo from "../assets/logo.png";

function Footer() {
  return (
    <div className="unique-container">
      <footer className="unique-footer">
        <div className="unique-footer-col">
          <a href="/" className="unique-brand">
            <img
              src={logo}
              alt="Logo"
              className="unique-brand-img"
              height={140}
              width={170}
            />
          
          </a>
          <p id="little-text">We Believe in Your Dreams, Let's Join Hand to Work them Out.</p>
        </div>

        <div className="unique-footer-col">
          <h5>Quick Links</h5>
          <ul>
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/#whyus">Features</a>
            </li>
            <li>
              <a href="/#testimonials">Testimonials</a>
            </li>
            <li>
              <a href="/#faq">FAQs</a>
            </li>
            <li>
              <a href="/#aboutus">About</a>
            </li>
          </ul>
        </div>

        <div className="unique-footer-col">
          <h5>Services</h5>
          <ul>
            <li>
              <a href="/#service">Loan</a>
            </li>
            <li>
              <a href="/#insurance">Insurance</a>
            </li>
            <li>
              <a href="/homeloan">Home Loan</a>
            </li>
            <li>
              <a href="/personalloan">Personal Loan</a>
            </li>
            <li>
              <a href="/businessloan">Business Loan</a>
            </li>
          </ul>
        </div>

        <div className="unique-footer-col">
          <h5>Bank Fincon</h5>
          <ul>
            <li>
              <a href="/aboutus">About Us</a>
            </li>
            <li>
              <a href="/contactus">Contact Us</a>
            </li>
            <li>
              <a href="/#statistics">Statistics</a>
            </li>
            <li>
              <a href="/contactus">Support</a>
            </li>
            <li>
              <a href="/">More</a>
            </li>
          </ul>
        </div>
<div className="unique-position">
        <div className="unique-footer-col unique-social-col">
          <h4>Follow Us</h4>
          <div className="unique-social-links">
            <a href="&">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a href="https://wa.me/+919136043666" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-whatsapp"></i>
            </a>
            <a href="https://www.instagram.com/bank_fincon?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-instagram"></i>
            </a>
            <a href="mailto:support@bankfincon.com" target="_blank" rel="noopener noreferrer">
              <i class="fa fa-envelope" ></i>
            </a>
          </div>
        </div></div>
      </footer>
      <center>
        <p className="unique-footer-text">© Bank Fincon 2024</p>
      </center>
    </div>
  );
}

export default Footer;
