import React from "react";
import "./Documentation.css";
function Documentation() {
  return (
    <div>
      <center>
        <br />
        <br />
        <h3 id="Documentation">
          <u>Documentation</u>
        </h3>
        <div className="documentation-card-container">
        <div className="documentation-card-left">
  <h3>Documentation For Salaried Individuals</h3>
  <p>
    <br />
    <strong>1.</strong> Form 16
    <br />
    <strong>2.</strong> Employee Identity Card
    <br />
    <strong>3.</strong> Latest 3 Months' Salary Slips
    <br />
    <strong>4.</strong> Bank Account Statement for the Last 6 Months
    <br />
    <strong>5.</strong> Evidence of Employment Duration
    <br />
  </p>
  <br />
</div>

          <div
  className="documentation-card-left"
  id="documentation-card-middle"
>
  <h3>Documentation For NRI Individuals</h3>
  <p>
    <br />
    <strong>1.</strong> Income Proof Documents for NRI Applicants
    <br />
    <strong>2.</strong> Property-Related Documents: Property Papers
    <br />
    <strong>3.</strong> Sale Deed
    <br />
    <strong>4.</strong> Stamped Agreement of Sale
    <br />
    <strong>5.</strong> Allotment Letter
    <br />
    <strong>6.</strong> No Objection Certificate (NOC) from:
    <br />
    &nbsp;&nbsp;&nbsp;&nbsp;- Housing Society
    <br />
    &nbsp;&nbsp;&nbsp;&nbsp;- Builder
    <br />
    <strong>7.</strong> Possession Certificate
    <br />
    <strong>8.</strong> Land Tax Receipt
    <br />
    <strong>9.</strong> Construction Cost Estimate
    <br />
    <strong>10.</strong> Bank Account Statement showing Payments Made to Seller or Builder
    <br />
    <strong>11.</strong> Payment Receipt for Payments Made to Seller or Builder
    <br />
    <strong>12.</strong> In case of resale property:
    <br />
    &nbsp;&nbsp;&nbsp;&nbsp;- Share Certificate
    <br />
    &nbsp;&nbsp;&nbsp;&nbsp;- Occupancy Certificate
    <br />
  </p>
</div>


          <div
  className="documentation-card-left"
  id="documentation-card-right"
>
  <h3>Documentation For Self-Employed Individuals</h3>
  <p>
    <br />
    <strong>1.</strong> PAN (Permanent Account Number)
    <br />
    <strong>2.</strong> Trade License
    <br />
    <strong>3.</strong> Partnership Deed (if applicable)
    <br />
    <strong>4.</strong> Articles of Association and Memorandum of Association
    <br />
    <strong>5.</strong> Import-Export Code (if applicable)
    <br />
    <strong>6.</strong> SEBI Registration Certificate (if applicable)
    <br />
    <strong>7.</strong> ROC (Registrar of Companies) Registration Certificate
    <br />
    <strong>8.</strong> Financial Statements Audited by a Chartered Accountant (CA)
    <br />
    <strong>9.</strong> Profit & Loss Account Statement
    <br />
    <strong>10.</strong> Balance Sheet
    <br />
    <strong>11.</strong> Bank Account Statement for the Last 6 Months
    <br />
    <strong>12.</strong> Professional Practice License (for Doctors, Consultants, etc.)
    <br />
    <strong>13.</strong> Registration Certificate for Shops, Factories, or Other Establishments
    <br />
    <strong>14.</strong> Business Address Proof
    <br />
  </p>
</div>

        </div>
      </center>
    </div>
  );
}

export default Documentation;
