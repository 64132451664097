import React from "react";
import "./AboutUsInfo.css";
import document from "../assets/Document1.png"
const AboutUs = () => {
  return (

    <div className="section-container">
  {/* Heading */}
  <h1 className="about-us-title">About Us</h1>

  {/* Section */}
  <section id="about-us" className="about-us-section">
    {/* Left Part */}
    <div id="about-us-left" className="about-us-left">
      <h2>Who We Are?</h2>
      <br />
      <p id="abt-first-para">
        Bank Fincon Provides Expert Financial Advisory Services For Banks And Institutions.
      </p>
      <br />
      <p id="abt-second-para">
        Bank Fincon is a Financial Consultancy Firm That Specializes in Providing Expert Advisory Services to Banks, Financial Institutions, and Businesses. They Offer Solutions in Areas Such as Risk Management, Regulatory Compliance, Financial Strategy, and Digital Transformation. Their Goal is to Optimize Financial Operations and Ensure Sustainable Growth in an Ever-Evolving Market Landscape.
      </p>
      <br />
      <button className="about-us-btn" onClick={()=> alert("Button Clicked")}>Book A Call Today</button>
    </div>

    {/* Right Part */}
    <div id="about-us-right" className="about-us-right">
      <div className="stat-item" id="experience">
        <h3>12+</h3>
        <p>Years of Experience</p>
      </div>
      <div className="stat-item" id="loans">
        <h3>8+</h3>
        <p>Loan Offered</p>
      </div>
      <div className="stat-item" id="lenders">
        <h3>6+</h3>
        <p>Top Money Lenders</p>
      </div>
      <div className="stat-item" id='insurance'>
        <h3>12+</h3>
        <p>Insurance Policies</p>
      </div>
      <div className="stat-item" id="interest-rates">
        <h3>%</h3>
        <p>Attractive Interest Rates</p>
      </div>
      <div className="stat-item" id="minimal-docs">
        <h3>
          <img src={document} alt="Minimal" />
        </h3>
        <p>Minimal Documentation</p>
      </div>
    </div>
  </section>
</div>

 
  );
};

export default AboutUs;
