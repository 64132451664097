import React from 'react'
import ContactInfo from '../Components/ContactInfo'
import ContactUsOption from '../Components/ContactUsOption'
import Navbar from "../Components/Navbar";







function ContactUs() {
  return (
    <div>
      <Navbar/>
      <ContactInfo />
      <ContactUsOption/>

    </div>
  )
}

export default ContactUs
