import React from 'react'
import "./Insurance.css";
import Card from './Card';
import "./Card.css";
import './Services.css'
import houseLoan from "../assets/homeinsurance.png";
import propertyAgainstLoan from "../assets/icons/loanAgainstProperty.png";
import personalLoan from "../assets/medicalinsurance.png";
import InsuranceImage from "../assets/icons/insuranceImage.png";
import protect from "../assets/icons/Protect.png";
import carLoan from "../assets/carinsurance.png";
import designText from "../assets/designText.png";

function Insurance() {
  return (
    <> <div id='insurance'>
    <h1 id='left-marg'>Insurance Plan<span>
            <img src={protect} alt="" />
          </span></h1>
    <p id='left-marg'>Protect What Matters Most - Comprehensive Insurance Solutions for Peace of Mind!</p>
    <div className="container">
    <div className="left-half">
      <div className="insurance-container-box" id= "insurance-text-heading">
        <div className="card-holder-box"    ><Card 
                  id="insurance-card"
                  image={houseLoan} 
                  title="Home Insurance" 
                  desc="Protect your home and belongings with reliable home insurance plans."
                  redirectPath="/contactus"
                />
                <Card 
                  id="insurance-card"
                  image={personalLoan} 
                  title="Medical Insurance" 
                  desc="Secure your health with reliable medical insurance plans."
                  redirectPath="/contactus"
                />
                <Card 
                  id="insurance-card"
                  image={propertyAgainstLoan} 
                  title="Property Insurance" 
                  desc="Protect your property with comprehensive insurance plans."
                  redirectPath="/contactus"
                />
                <Card 
                  id="insurance-card"
                  image={carLoan} 
                  title="Car Insurance" 
                  desc="Protect your car with comprehensive coverage and peace of mind."
                  redirectPath="/contactus"
                />
                
                </div>
               
      </div> <center><button className="btn-view-more" id='insurance-more-button' ><a href="/contactus">Learn More+{"(All Insurances Available)"}</a></button></center>
    </div>
    <div className="right-half" id='position'>
        <img src={InsuranceImage} className='heroImage' id="heroImage"  alt="" />
        <img src={designText} alt="" id='design-img'/>
        
    </div>

</div></div>
    </>
  )
}

export default Insurance
