import React, { useState } from "react";
import "./EMICalculator.css"; // CSS file for styling

const EMICalculator = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [principal, setPrincipal] = useState("");
  const [rate, setRate] = useState("");
  const [tenure, setTenure] = useState("");
  const [emi, setEmi] = useState(null);

  const calculateEMI = () => {
    const P = parseFloat(principal);
    const R = parseFloat(rate) / 12 / 100;
    const N = parseInt(tenure) * 12;

    if (P && R && N) {
      const emiValue = (P * R * Math.pow(1 + R, N)) / (Math.pow(1 + R, N) - 1);
      setEmi(emiValue.toFixed(2));
    } else {
      setEmi("Please enter valid values.");
    }
  };

  return (
    <div className="emi-container">
      <button className="toggle-button" onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? "Close EMI Calculator" : "Open EMI Calculator"}
      </button>

      {isOpen && (
        <div className="emi-sticky-note">
          <h3>EMI Calculator</h3>
          <input
            type="number"
            placeholder="Principal Amount"
            value={principal}
            onChange={(e) => setPrincipal(e.target.value)}
          />
          <input
            type="number"
            placeholder="Rate of Interest (%)"
            value={rate}
            onChange={(e) => setRate(e.target.value)}
          />
          <input
            type="number"
            placeholder="Tenure (Years)"
            value={tenure}
            onChange={(e) => setTenure(e.target.value)}
          />
          <button onClick={calculateEMI}>Calculate EMI</button>
          {emi && <div className="emi-result">EMI: ₹{emi}</div>}
        </div>
      )}
    </div>
  );
};

export default EMICalculator;
